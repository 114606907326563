<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "NotificationsSystemIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapGetters("app", {
      selectedServiceProviderAccountCombination:
        "selectedServiceProviderAccountCombination"
    })
  },

  methods: {
    ...mapActions("users", {
      getUsers: "getUsers"
    }),

    initContent() {
      return {
        notification_subject: "",
        content: "",
        target_user_group: "all_users",
        start_date: "",
        end_date: "",
        recipients: []
      };
    },

    async refreshScopeTemplate() {
      if (
        this.selectedAccountId !== "all" ||
        this.selectedServiceProviderId !== "all"
      ) {
        this.forceShowNotScoped = true;
        this.forceScopeName = "Service Provider";
      } else {
        this.forceShowNotScoped = false;
        await this.getUsers({ fetch_all: 1 });
      }
    }
  },

  async created() {
    await this.getUsers({ fetch_all: 1 });
  },

  watch: {
    selectedServiceProviderAccountCombination: {
      immediate: true,
      async handler() {
        await this.refreshScopeTemplate();
      }
    }
  },

  mounted() {
    this.contentType = "Notification";
    this.primaryKey = "notification_message_id";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
